<script setup lang="ts">
import { getEdgeClasses } from '~/lib/getEdgeClasses'

import {
  ITEMS_PER_ROW_LARGE_GRID,
  ITEMS_PER_ROW_SMALL_GRID,
} from '~/configuration/global.configuration'
import type {
  ProductListBlockContent,
} from '~/types/storyblok/product-list-block'
import {
  normalizeProductListBlock,
} from '~/types/storyblok/product-list-block'
import { type Product, isProduct } from '~/types/models/product'
import { productUrl } from '~/lib/routing'
import type { trackBlockClickEmit } from '~/types/track-block-click'

interface Props {
  blok: ProductListBlockContent
  products?: Product[]
}

const props = defineProps<Props>()
const emit = defineEmits<trackBlockClickEmit>()

const storyblokStore = useStoryblokStore()

const data = normalizeProductListBlock(props.blok, storyblokStore.state.categories)

const { data: fetchedProducts } = await useLazyAsyncData(
  props.blok._uid,
  async () => {
    if (props.products)
      return Promise.resolve(undefined)

    const { $products } = useNuxtApp()

    const limit
      = Number(data.gridType.numberOfRows)
      * (data.gridType.component === 'product-grid-large'
        ? ITEMS_PER_ROW_LARGE_GRID
        : ITEMS_PER_ROW_SMALL_GRID)
    const variables = data.category ? { $collection: `category-${data.category}` } : undefined

    const { items } = await $products.fetchProducts({
      variables,
      limit,
      page: 1,
    })

    return items.flat().filter(isProduct)
  },
)

const products = computed(() => props.products || fetchedProducts.value)
</script>

<template>
  <div v-if="blok" v-editable="blok" class="container" :class="[getEdgeClasses(data.edgeStyle)]">
    <h3 v-if="data.title" class="title">
      {{ data.title }}
    </h3>
    <div
      class="grid" :class="[
        { 'grid--small': data.gridType.component === 'product-grid-small' },
        { 'grid--large': data.gridType.component === 'product-grid-large' },
      ]"
    >
      <ProductCard
        v-for="product in products"
        :key="product.sku"
        :product="product"
        :has-quick-shop="true"
        :grid-type="data.gridType.component"
        @product-click="p => emit('trackBlockClick', productUrl(p.sku), 'product')"
      />
    </div>
    <ButtonIcon
      v-if="data.link.id && data.link.cached_url"
      class="btn"
      :text="$t(data.label)"
      left-icon="bullet"
      right-icon="forward"
      :blinking="true"
      :background-color="data.ctaBackgroundColor"
      :text-color="data.ctaTextColor"
      data-test="cta-internal-link-btn"
      tag="nuxt-link"
      :target="data.link.cached_url.startsWith('http') ? '_blank' : '_self'"
      :to="$url(data.link.cached_url)"
      @click="() => emit('trackBlockClick', $url(data.link.cached_url), 'button')"
    />
  </div>
</template>

<style lang="scss" scoped>
@import 'assets/scss/rules/breakpoints';
@import 'assets/scss/typography/headings';
@import 'assets/scss/rules/edges';
.container {
  background: white;
}
.grid {
  display: grid;
  border-top: 1px solid var(--gray-light);
  border-bottom: 1px solid var(--gray-light);
  background-color: var(--gray-light);

  &--small {
    background-color: var(--white);
    grid-template-columns: repeat(3, 1fr);
    @media (min-width: $tablet) {
      grid-template-columns: repeat(8, 1fr);
    }
  }
  &--large {
    grid-gap: 1px;
    grid-template-columns: repeat(2, 1fr);
    @media (min-width: $tablet) {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}
.title {
  padding: 1.6rem;
  @include h6;
  @media (min-width: $tablet) {
    padding: 2.4rem;
    @include h5;
  }
}

.btn {
  margin: 4.8rem 2.4rem;
}
.teeth {
  @include teeth;
  margin-top: -1.2rem;
}

.scallop {
  @include scallop;
  margin-top: -1.2rem;
}

.wave {
  @include wave;
  margin-top: -1.2rem;
}
</style>
