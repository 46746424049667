import { z } from 'zod'
import consola from 'consola'
import type { StoryblokContent } from '../storyblok'
import type { EdgeStyles } from './shared'
import { EDGE_STYLES } from './shared'
import type Storyblok from '~/types/vendors/storyblok'
import type { Category } from '~/utils/categories/normalizeCategories'

const GRID_TYPES = ['product-grid-small', 'product-grid-large'] as const

type GridType = (typeof GRID_TYPES)[number]

export interface ProductListBlockContent extends StoryblokContent {
  component: 'product-list-block'
  title: Storyblok.Text
  label: Storyblok.Text
  link: {
    id: string
    url: string
    linktype: string
    fieldtype: string
    cached_url: string
  }
  ctaBackgroundColor: string
  ctaTextColor: string
  category: {
    _uid: string
    category: string // uuid of category Story
    component: string
    _editable: string
  }[]
  gridType: {
    _uid: string
    component: GridType
    numberOfRows: string
    _editable: string
  }[]
  edgeStyle: EdgeStyles
  handpicked: string // Comma separated list of productSkus
  xo_tag?: string // Overwrites the category
}

const ProductListBlock = z.object({
  title: z.string(),
  label: z.string(),
  link: z.object({
    id: z.string(),
    cached_url: z.string(),
  }),
  ctaBackgroundColor: z.string(),
  ctaTextColor: z.string(),
  category: z.string().optional().describe('The id of a category'),
  categoryTag: z.string().optional().describe('Overwrites the category id'),
  gridType: z.object({
    component: z.enum(GRID_TYPES).default('product-grid-small'),
    numberOfRows: z.string().default('4'),
  }),
  edgeStyle: z.enum(EDGE_STYLES),
})

export type ProductListBlock = z.infer<typeof ProductListBlock>

export function normalizeProductListBlock(
  schema: ProductListBlockContent,
  categories?: Map<string, Category>,
): ProductListBlock {
  const category = schema.category?.[0] && categories && categories.get(schema.category[0].category)

  const result = ProductListBlock.safeParse({
    ...schema,
    category: category?.content.id,
    gridType: schema.gridType[0],
    edgeStyle: schema.edgeStyle || 'none',
    categoryTag: schema.xo_tag,
  })

  if (result.success) {
    return result.data
  }
  else {
    const errors = Object.entries(result.error.format()).slice(1)
    consola.warn(`Problem with product list block: ${schema.title}. Issues: `)
    // @ts-expect-error _errors is not in the type definition
    consola.box(errors.map(([key, value]) => `${key}: ${value._errors.join(', ')}`).join('\n'))

    return {
      title: '',
      label: '',
      category: '',
      gridType: {
        component: 'product-grid-small',
        numberOfRows: '4',
      },
      link: { cached_url: '' },
      ctaBackgroundColor: '',
      ctaTextColor: '',
      edgeStyle: 'none',
    }
  }
}

export function isProductListBlock(block: unknown): block is ProductListBlockContent {
  return (
    typeof block === 'object'
    && block !== null
    && (block as ProductListBlockContent).component === 'product-list-block'
  )
}
